<template>
  <div>
    <b-row class="position-relative pb-2">
      <h4 class="col-12">Analytics</h4>
      <b-link class="see-all-link" @click="$emit('change_tab', 1)">{{ $t('creator.viewAll') }}</b-link>
      <b-col class="d-flex align-items-stretch pr-0 mb-1">
        <div class="border-box-analytics p-1 w-100 overflow-hidden">
          <p>{{ $t('instagram.followersTooltip') }}</p>
          <strong v-if="exists_metrics">{{ metrics.followers }}</strong>
          <strong v-else>{{ getFormat(network_selected.followers) }}</strong>

        </div>
      </b-col>
      <b-col class="d-flex align-items-stretch mb-1" v-if="network_selected.network === 'instagram'">
        <div 
          class="border-box-analytics p-1 w-100 position-relative d-flex align-items-center cursor-pointer" 
          @click="$emit('change_tab', 1)"
        >
          <div v-if="exists_metrics">
            <p>{{ $t('instagram.influenceLevel')}}</p>
            <strong>{{ metrics.aqs }} / 100</strong>
          </div>
          <p class="dots-p w-100 position-relative m-0" v-else>
            <span class="dots-text">{{$t('instagram.influenceLevel')}} </span>
            <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
          </p>
        </div>
      </b-col>

      <b-col class="d-flex align-items-stretch mb-1" v-if="network_selected.network !== 'twitch'">
        <div 
          class="border-box-analytics p-1 w-100 position-relative d-flex align-items-center cursor-pointer" 
          @click="$emit('change_tab', 1)"
        >
          <div v-if="exists_metrics">
            <p>Engagement Rate</p>
            <strong>{{ metrics.er }} %</strong>
          </div>
          <p class="dots-p w-100 position-relative m-0" v-else>
            <span class="dots-text">Engagement Rate</span>
            <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
          </p>
        </div>
      </b-col>
      <b-col class="d-flex align-items-stretch mb-1" v-if="network_selected.network === 'tiktok' || network_selected.network === 'twitch'">
        <div 
          class="border-box-analytics p-1 w-100 position-relative d-flex align-items-center cursor-pointer" 
          @click="$emit('change_tab', 1)"
        >
          <div v-if="exists_metrics">
            <p>{{ $t('search.averageViewsTiktok') }}</p>
            <strong>{{ metrics.views_avg }}</strong>
          </div>
          <p class="dots-p w-100 position-relative m-0" v-else>
            <span class="dots-text">{{ $t('search.averageViewsTiktok') }}</span>
            <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
          </p>
        </div>
      </b-col>
      <b-col class="d-flex align-items-stretch mb-1" v-if="network_selected.network === 'twitch'">
        <div 
          class="border-box-analytics p-1 w-100 position-relative d-flex align-items-center cursor-pointer" 
          @click="$emit('change_tab', 1)"
        >
          <div v-if="exists_metrics">
            <p>{{ $t('creator.hours_streamed') }}</p>
            <strong>{{ metrics.hours_streamed }}</strong>
          </div>
          <p class="dots-p w-100 position-relative m-0" v-else>
            <span class="dots-text">{{ $t('creator.hours_streamed') }}</span>
            <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
          </p>
        </div>
      </b-col>

      <b-col class="d-flex align-items-stretch pr-0 mb-1" v-if="network_selected.network === 'twitter'">
        <div 
          class="border-box-analytics p-1 w-100 position-relative d-flex align-items-center cursor-pointer" 
          @click="$emit('change_tab', 1)"
        >
          <div v-if="exists_metrics">
            <p>{{$t('twitter.averageRes')}}</p>
            <strong>{{ metrics.reply_avg }} %</strong>
          </div>
          <p class="dots-p w-100 position-relative m-0" v-else>
            <span class="dots-text">{{$t('twitter.averageRes')}}</span>
            <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
} from 'bootstrap-vue';
import { getFormat } from '@/libs/utils/formats';

export default {
  name: 'overviewNetwork',
  components: {
    BRow,
    BCol,
    BLink,
  },
  props: {
    network_selected: {
      type: Object,
    },
    metrics: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      getFormat,
    }
  },
  computed: {
    exists_metrics() {
      return Object.keys(this.metrics).length > 0
    }
  }
}
</script>
<style scoped lang="scss">
.container-lock-icon {
  display: block;
  height: 1.6em;
  width: 1.6em;
  background-color: white;
  position: absolute;
  right: 0px;
  bottom: -3px;
}
.dots-p {
  background: inherit;
  margin: 0 auto;
  margin-bottom: 1em;
  position: relative;
}
.dots-p:before {
  content: '';
  position: absolute;
  bottom: 0.2rem;
  width: 100%;
  height: 0;
  border-bottom: 2px dotted;
  border-color: #ccc;
}
.dots-text {
  background: inherit;
  display: inline;
  z-index: 1;
  padding-right: .2rem;
  position: relative;
  background-color: white;
  white-space: normal;
}
.overflow-hidden {
  overflow: hidden !important;

}
.see-all-link {
  position: absolute;
  right: 0;
}
.see-all-link:hover {
  text-decoration: underline;
}
</style>